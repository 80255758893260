import React from 'react'
import styled from 'styled-components'
import { Wrapper, Grid, H, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import {
  SECURITY_EMAIL_ADDRESS,
  SECURITY_REPORTING_PUBLIC_KEY_URL,
} from 'config'
import ExternalLink from 'components/ExternalLink'
import DefaultLayout from 'layouts/DefaultLayout'

const StyledBulletList = styled.ul`
  list-style-type: disc;
  margin-left: ${GTR.M};
  line-height: 1.5;

  > li {
    margin-bottom: ${GTR.XS};
  }
`

const reportingSteps = [
  `We’ll acknowledge your report & tell you the best way to track the
    status of your issue.`,
  `We’ll investigate the issue and determine how it impacts our
    products. We won’t disclose issues until our investigation is
    finished, but we’ll work with you to ensure we fully understand
    the issue.`,
  `Once the issue is resolved, we’ll post a security update along
    with thanks and credit for the discovery.`,
]

const SecurityReporting = () => (
  <DefaultLayout
    title="Security reporting"
    description="How to report potential issues with the Farewill website."
  >
    <Grid container>
      <Grid.Item spanFromM={9} spanFromL={6}>
        <H size="XL" decorative>
          Farewill security reporting
        </H>

        <Wrapper separator>
          <H size="M">We appreciate your concern</H>
          <P>
            Keeping customer data safe and secure is a huge responsibility and a
            top priority. We work hard to protect our customers from the latest
            threats. Your input and feedback on our security is always
            appreciated.
          </P>
        </Wrapper>

        <Wrapper separator>
          <H size="M">Reporting security problems</H>
          <P>
            Send any questions or reports directly to{' '}
            <ExternalLink href={`mailto:${SECURITY_EMAIL_ADDRESS}`}>
              {SECURITY_EMAIL_ADDRESS}
            </ExternalLink>
            . Use our{' '}
            <ExternalLink href={SECURITY_REPORTING_PUBLIC_KEY_URL} download>
              public key
            </ExternalLink>{' '}
            to keep your message safe and please provide us with a secure way to
            respond. We’ll get back to you as soon as we can, usually within 24
            hours.
          </P>
        </Wrapper>

        <Wrapper separator>
          <H size="M">Tracking and disclosing security issues</H>
          <P>
            Have you discovered a web security flaw that might impact our
            products? Please let us know. If you submit a report, here’s what
            will happen:
          </P>
          <StyledBulletList>
            {reportingSteps.map((content) => (
              <li key={content.substring(0, 9)}>{content}</li>
            ))}
          </StyledBulletList>
        </Wrapper>

        <Wrapper separator>
          <H size="M">Thanks for working with us</H>
          <P>
            While we work with security experts and actively try to track down
            any potential security issues, any help to that end is greatly
            received.
          </P>
        </Wrapper>
      </Grid.Item>
    </Grid>
  </DefaultLayout>
)

export default SecurityReporting
